<template>
  <div class="catalogue">
    <el-collapse v-model="activeNames" class="course_catalogue">
      <el-collapse-item
        v-for="item in contentList"
        :key="item.chapterId"
        :title="item.title"
        :name="item.chapterId"
      >
        <div class="sub_content">
          <div
            v-for="(pritem, prindex) in item.children"
            :key="pritem.periodId"
            class="period_item"
          >
            <div class="period_header">
              <span>课时{{ prindex + 1 }}</span>
              <span>{{ pritem.title }}</span>
            </div>
            <div
              class="doc_item flex_space"
              v-for="acitem in pritem.children"
              :key="acitem.actionId"
            >
              <div class="doc_desc">
                <span>
                  <i class="iconfont" :class="acitem.iconName"></i>
                  {{ acitem.typeName }}：</span
                >
                <span>{{ acitem.title }}</span>
              </div>
              <div class="doc_operate">
                <el-button
                  type="primary"
                  size="mini"
                  @click="
                    $router.push(
                      `/rec/mooc-detail/${$route.params.id}/${acitem.actionId}`
                    )
                  "
                >
                  开始
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "Catalogue",
  props: { list: Object },
  data() {
    return {
      contentList: [],
      activeNames: "1",
      actionList: [],
      chapterList: [],
      periodList: [],
    };
  },
  watch: {
    list(newVal) {
      if (newVal) {
        this.chapterList = this.list.chapterList;
        this.periodList = this.list.periodList;
        this.actionList = this.list.actionList.map((item) => {
          let typeName = "";
          let iconName = "";
          switch (item.actionType) {
            case 1: {
              iconName = "icon-shipintianchong";
              typeName = "视频";
              break;
            }
            case 2: {
              iconName = "icon-wendang";
              typeName = "文档";
              break;
            }
            case 3: {
              iconName = "icon-zhibo1";
              typeName = "直播";
              break;
            }
            case 4: {
              iconName = "icon-zuoye-copy";
              typeName = "试题";
              break;
            }
            case 5: {
              iconName = "icon-ceshitiku";
              typeName = "测验";
              break;
            }
            case 6: {
              iconName = "icon-bianji";
              typeName = "作业";
              break;
            }
          }
          return {
            ...item,
            iconName: iconName,
            typeName: typeName,
          };
        });
        this.dealList();
      }
    },
  },
  methods: {
    dealList() {
      // 这三个列表开始整理
      let cid = -1;
      let pid = -1;
      let list = [];
      this.chapterList.forEach((chpItem) => {
        list.push(chpItem);
        list[++cid].children = [];
        pid = -1;
        this.periodList
          .filter((pItem) => pItem.chapterId === chpItem.chapterId)
          .forEach((pdItem) => {
            list[cid].children.push(pdItem);
            list[cid].children[++pid].children = [];
            this.actionList
              .filter((acItem) => acItem.periodId === pdItem.periodId)
              .forEach((acItem) => {
                list[cid].children[pid].children.push(acItem);
              });
          });
      });
      this.contentList = list;
    },
  },
};
</script>

<style lang="scss" scoped>
.catalogue {
  .flex_space {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

<style lang="scss">
.course_catalogue {
  border-top: 0px;
  .el-collapse-item {
    margin-bottom: 30px;
    .el-collapse-item__header {
      background-color: #f6f6f6;
      border-bottom: 0px;
      height: 60px;
      line-height: 60px;
      padding: 0 30px;
      font-size: 18px;
      color: #333333;
    }
    .el-collapse-item__wrap {
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-top: 30px;
      padding-left: 40px;
      border-bottom: 0px;
      border-left: 1px solid #dddddd;
      .el-collapse-item__content {
        .sub_content {
          .period_item {
            margin-bottom: 40px;
            .period_header {
              position: relative;
              margin-bottom: 30px;
              span {
                display: inline-block;
              }
              // .point {
              //   position: absolute;
              //   left: -8px;
              //   top: 8px;
              //   width: 16px;
              //   height: 16px;
              //   border-radius: 8px;
              //   background-color: #f27200;
              //   z-index: 999;
              // }
              span:nth-child(1) {
                width: 60px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                color: #f27200;
                font-size: 14px;
                background-color: rgba($color: #f27200, $alpha: 0.1);
                margin-right: 10px;
              }
              span:nth-child(2) {
                color: #333333;
                font-size: 16px;
              }
            }
            .doc_item {
              width: calc(100% - 52px);
              height: 60px;
              padding: 0 25px;
              border: 1px solid #eeeeee;
              border-radius: 4px;
              margin-bottom: 20px;
              .doc_desc {
                span:nth-child(1) {
                  color: #333333;
                  font-size: 14px;
                  margin-right: 20px;
                  font-weight: bold;
                }
                span:nth-child(2) {
                  color: #666666;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>