<template>
  <div class="introduce">
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "Introduce",
  props: {
    content: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scope>
.introduce {
  margin: 20px 0;
  line-height: 20px;
  .text {
    color: #333333;
    font-size: 16px;
    line-height: 24px;
  }
  .img {
    width: 100%;
    height: 675px;
    background-color: rgba($color: #a4d23d, $alpha: 0.5);
    margin: 20px 0;
  }
}
</style>