<template>
  <div class="mooc_introduce">
    <div class="bread">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/rec/list/3' }">
          MOOC
        </el-breadcrumb-item>
        <el-breadcrumb-item class="highlight">
          {{ detailInfo.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="course_introduce">
      <div class="course_img">
        <img :src="detailInfo.cover" alt="" />
      </div>
      <div class="course_infos">
        <div class="course_infos_header flex_space">
          <div class="course_infos_header_l flex">
            <span class="tag">
              {{ detailInfo.gradeName }} · {{ detailInfo.subjectName }}
            </span>
            <span class="course_name">{{ detailInfo.title }}</span>
          </div>
          <div class="course_infos_header_r flex">
            <div class="share">
              <el-button type="text"> 分享 </el-button>
            </div>

            <div>
              <el-button @click="handleFavorite" type="text" :disabled="lock">
                <div v-if="isFavorite" class="like">取消收藏</div>
                <div v-else>收藏</div>
              </el-button>
            </div>
          </div>
        </div>
        <div class="course_infos_content">
          <div class="teacher">
            主讲老师：
            {{ detailInfo.lecturerName }}{{ detailInfo.teamTeacherName }}
          </div>
          <div class="class_time">
            <span v-if="detailInfo.countChapter">
              课时：共{{ detailInfo.countChapter }}个课时
            </span>
            <span v-else> 暂无课时 </span>
          </div>
          <div class="favorite">
            <div>{{ detailInfo.countJoin }}人学过</div>
            <div>{{ detailInfo.countCollect }}人收藏</div>
          </div>
        </div>
        <div class="course_infos_footer">
          <span
            v-if="detailInfo.classId && detailInfo.classStatusId === 0"
            class="tips"
          >
            申请审核中...
          </span>
          <el-button
            v-if="detailInfo.classId && detailInfo.classStatusId === 1"
            type="primary"
            size="mini"
          >
            开始学习
          </el-button>
          <el-button
            v-if="!detailInfo.classId || detailInfo.classStatusId === 2"
            @click="getClassList"
            type="primary"
            size="mini"
          >
            加入学习
          </el-button>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleTabClick" class="mooc_tabs">
      <el-tab-pane label="介绍" name="first">
        <Introduce
          v-if="activeName === 'first'"
          :content="detailInfo.description"
        ></Introduce>
      </el-tab-pane>
      <el-tab-pane
        label="目录"
        name="second"
        v-if="detailInfo.classId && detailInfo.classStatusId === 1"
      >
        <Catalogue
          v-if="activeName === 'second'"
          :list="contextList"
        ></Catalogue>
      </el-tab-pane>
      <el-tab-pane label="学习圈" name="third">Role</el-tab-pane>
    </el-tabs>
    <!-- 选择班级弹窗 -->
    <el-dialog
      v-if="openChooseDlg"
      v-model="openChooseDlg"
      :close-on-click-modal="false"
      title="选择班级"
      custom-class="chooseClsDialog"
      append-to-body
      width="800px"
    >
      <el-table :data="clsTableData" style="width: 100%">
        <el-table-column align="center" prop="className" label="班级名称" />
        <el-table-column align="center" prop="joinTypeName" label="加入方式" />
        <el-table-column align="center" label="人数">
          <template #default="scope">
            <label class="orange"> {{ scope.row.joinCount }} </label>
            /{{ scope.row.joinLimit }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template #default="scope">
            <span
              class="operate_btn"
              v-if="
                scope.row.joinType === 2 &&
                scope.row.joinCount < scope.row.joinLimit
              "
              @click="handleJoinClsByCode(scope.row)"
            >
              输入邀请码
            </span>
            <span
              v-if="
                (scope.row.joinType === 1 &&
                  scope.row.joinCount < scope.row.joinLimit) ||
                (scope.row.joinType === 3 &&
                  scope.row.joinCount < scope.row.joinLimit)
              "
              @click="joinClass(scope.row)"
              class="operate_btn"
            >
              加入
            </span>
            <span
              v-if="
                scope.row.joinCount >= scope.row.joinLimit ||
                scope.row.joinCount >= scope.row.joinLimit
              "
              @click="handleFullJoin(scope.row)"
              class="operate_btn"
            >
              申请加入
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 填写邀请码弹窗 -->
    <el-dialog
      v-if="openInviteDlg"
      v-model="openInviteDlg"
      title="提示"
      :close-on-click-modal="false"
      custom-class="invitationCodeDialog"
      append-to-body
      width="600px"
    >
      <el-form
        ref="joinClassForm"
        :model="joinClassForm"
        :rules="joinClassRule"
      >
        <el-form-item prop="inviteCode">
          <el-input
            v-model="joinClassForm.inviteCode"
            placeholder="请输入邀请码"
            size="mini"
            clearable
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="joinClassByCode" type="primary" size="mini">
            确认
          </el-button>
          <el-button @click="handleCancel" size="mini"> 取消 </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 申请加入弹窗 -->
    <el-dialog
      v-if="openApplyDlg"
      v-model="openApplyDlg"
      title="提示"
      :close-on-click-modal="false"
      append-to-body
      custom-class="applyJoinDialog"
      width="600px"
    >
      <span>当前班级人数已满，是否申请加入？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="applyClass" type="primary" size="mini">
            申请加入
          </el-button>
          <el-button @click="openApplyDlg = false" type="info" size="mini">
            取消
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 我想学习弹窗 -->
    <el-dialog
      v-model="openNoClsDlg"
      :close-on-click-modal="false"
      title="提示"
      custom-class="wantStudyDialog"
      width="600px"
      append-to-body
    >
      <span>
        老师还未创建学习班级，暂时无法加入学习哦，点击我想学习按钮催促老师开课
        吧！
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              openStudyReason = true;
              openNoClsDlg = false;
            "
            size="mini"
            type="primary"
          >
            我想学习
          </el-button>
          <el-button @click="openNoClsDlg = false" size="mini" type="info">
            取消
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 学习申请弹窗 -->
    <el-dialog
      v-model="openStudyReason"
      :close-on-click-modal="false"
      custom-class="applyDialog"
      title="学习申请"
      width="600px"
      append-to-body
    >
      <el-form :model="applyForm" label-position="top">
        <el-form-item label="请输入申请理由:">
          <el-input
            v-model="applyForm.reason"
            type="textarea"
            :rows="5"
            placeholder="请输入申请理由..."
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="applyClass" size="mini" type="primary">
            提交
          </el-button>
          <el-button @click="openStudyReason = false" size="mini" type="info">
            取消
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import courseApi from "@/api/course";
import Introduce from "./components/Introduce.vue";
import Catalogue from "./components/Catalogue.vue";
import { mapState } from "vuex";

export default {
  components: {
    Introduce,
    Catalogue,
  },
  data() {
    return {
      // 班级id
      courseId: "",
      // 班级详情信息
      detailInfo: {
        classStatusId: 0,
      },
      // 学号
      userCode: "",
      // 收藏所用
      isFavorite: false,
      favoritesId: "",
      lock: false,
      // 是否需要审核
      isApply: false,
      // 课程目录信息
      contextList: {},
      // 选项卡
      activeName: "first",
      // 选中的班级
      activeCls: {},
      // 申请管理
      applyForm: {
        reason: "",
      },
      // 学习申请弹窗
      openStudyReason: false,
      // 选择班级弹窗
      openChooseDlg: false,
      // 班级列表
      clsTableData: [],
      // 加入班级表单
      joinClassForm: {},
      // 加入班级规则
      joinClassRule: {
        inviteCode: [
          {
            required: true,
            trigger: ["change", "blur"],
            message: "请输入邀请码",
          },
        ],
      },
      // 输入邀请码弹窗
      openInviteDlg: false,
      // 申请加入弹窗
      openApplyDlg: false,
      // 如果没有班级
      openNoClsDlg: false,
    };
  },
  computed: {
    ...mapState(["userinfo"]),
  },
  mounted() {
    this.courseId = this.$route.params.id;
    this.userCode = localStorage.getItem("userCode");
    this.getLikeList();
    this.getDetail();
  },
  methods: {
    // 点击 选项卡
    handleTabClick() {
      if (this.activeName === "second") {
        this.getContextList();
      }
    },
    // 根据我的收藏列表判断该课程是否被收藏
    async getLikeList() {
      const res = await courseApi.MyLiekCourse("");
      if (res && res.code === 200) {
        const index = res.data.list.findIndex((item) => {
          return item.refId === this.courseId;
        });
        if (index !== -1) {
          this.isFavorite = true;
          this.favoritesId = res.data.list[index].favoritesId;
        }
      }
    },
    // 获取课程详情
    async getDetail() {
      const res = await courseApi.GetMoocDetail({ courseId: this.courseId });
      if (res && res.code === 200) {
        let teacher = [];
        if (res.data.teamTeacherList) {
          teacher = res.data.teamTeacherList.map((item) => {
            return item.name;
          });
        }
        this.detailInfo = {
          ...res.data,
          teamTeacherName: teacher.length ? "、" + teacher.join("、") : "",
        };
      }
    },
    // 获取目录列表
    async getContextList() {
      const res = await courseApi.GetContextList({ courseId: this.courseId });
      if (res && res.data) {
        this.contextList = res.data;
      }
    },
    // 获取班级列表
    async getClassList() {
      const res = await courseApi.GetClassList({
        courseId: this.courseId,
        statusId: 1,
      });
      if (res && res.code === 200) {
        const now = new Date().getTime();
        this.clsTableData = res.data.list
          .filter((item) => item.joinTimeStart < now && item.joinTimeStop > now)
          .map((item) => {
            let joinTypeName = "";
            switch (item.joinType) {
              case 1: {
                joinTypeName = "公开";
                break;
              }
              case 2: {
                joinTypeName = "邀请码";
                break;
              }
              case 3: {
                joinTypeName = "指定范围";
                break;
              }
            }
            return {
              ...item,
              joinTypeName: joinTypeName,
            };
          });

        if (this.clsTableData && this.clsTableData.length) {
          this.openChooseDlg = true;
        } else {
          this.openNoClsDlg = true;
        }
      }
    },
    // 收藏课程
    async handleFavorite() {
      this.lock = true;
      if (this.isFavorite) {
        const res = await courseApi.DeleteLikeCourse({
          favoritesId: this.favoritesId,
        });
        if (res && res.code === 200) {
          this.$message.success("取消收藏成功！");
          this.isFavorite = false;
          this.lock = false;
          this.detailInfo.countCollect--;
        }
      } else {
        const res = await courseApi.SaveLikeCourse({
          refId: this.courseId,
          type: 3,
        });
        if (res && res.code === 200) {
          this.$message.success("收藏成功！");
          this.getLikeList();
          this.lock = false;
          this.detailInfo.countCollect++;
        }
      }
      this.lock = false;
    },
    //加入班级
    async joinClass(row) {
      if (row.classId) {
        this.activeCls = row;
      }
      const res = await courseApi.JoinMoocClass({
        classId: this.activeCls.classId,
        inviteCode: this.joinClassForm.inviteCode,
        memberId: this.userinfo.userId,
        schoolId: this.userinfo.orgCode,
        schoolName: this.userinfo.orgName,
        memberIdentity: 0,
        memberName: this.userinfo.name,
        memberPhoto: this.userinfo.avatar,
      });
      if (res && res.code === 200) {
        if (this.isApply) {
          this.$message({
            type: "success",
            message: "已发送审核",
          });
        } else {
          this.$message.success("加入成功");
          this.$router.go(0);
          this.handleCancel();
        }

        this.getClassList();
        this.handleCancel();
      }
    },
    // 申请加入
    async applyClass() {
      const res = await courseApi.ApplyMooc({
        classId: this.activeCls.classId,
        courseId: this.courseId,
        reason: this.applyForm.reason,
        memberId: this.userinfo.userId,
        memberIdentity: this.userCode,
        memberName: this.userinfo.name,
        memberPhoto: this.userinfo.avatar,
        schoolId: this.userinfo.orgCode,
        schoolName: this.userinfo.orgName,
      });
      if (res && res.code === 200) {
        this.$message.success("申请加入成功！");
        this.handleCancel();
      }
    },
    // 满人时的加入班级
    handleFullJoin(row) {
      this.openChooseDlg = false;
      this.openApplyDlg = true;
      this.activeCls = row;
      this.isApply = true;
    },
    // 加入班级-邀请码
    joinClassByCode() {
      this.$refs.joinClassForm.validate(async (valid) => {
        if (valid) {
          if (this.joinClassForm.inviteCode === this.activeCls.inviteCode) {
            const res = await courseApi.JoinMoocClassByCode({
              classId: this.activeCls.classId,
              inviteCode: this.joinClassForm.inviteCode,
              memberId: this.userinfo.userId,
              schoolId: this.userinfo.orgCode,
              schoolName: this.userinfo.orgName,
              memberIdentity: this.userCode,
              memberName: this.userinfo.name,
              memberPhoto: this.userinfo.avatar,
            });
            if (res && res.code === 200) {
              this.$message.success("加入成功");
              this.getClassList();
              this.handleCancel();
              // this.$router.go(0);
            }
          } else {
            this.$message({
              type: "warning",
              message: "邀请码错误",
            });
          }
        }
      });
    },
    //加入班级-输入邀请码
    handleJoinClsByCode(row) {
      this.activeCls = row;
      this.openChooseDlg = false;
      this.openInviteDlg = true;
    },
    handleCancel() {
      this.openNoClsDlg = false;
      this.openChooseDlg = false;
      this.openInviteDlg = false;
      this.openApplyDlg = false;
      this.openStudyReason = false;
      this.applyForm.reason = "";
      this.isApply = false;
      this.joinClassForm = {};
      this.activeCls = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.mooc_introduce {
  width: 1200px;
  margin: 0 auto;
  min-height: calc(100% - 180px);
  .flex_space {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .bread {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
  }
  .course_introduce {
    display: flex;
    width: 100%;
    margin-bottom: 45px;
    .course_img {
      width: 400px;
      height: 200px;
      margin-right: 30px;
      background-color: rgba($color: #f27200, $alpha: 0.2);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .course_infos {
      width: calc(100% - 430px);
      height: 200px;
      .course_infos_header {
        margin-bottom: 20px;
        .course_infos_header_l {
          span {
            display: inline-block;
          }
          .tag {
            height: 24px;
            padding: 2px 5px;
            line-height: 24px;
            text-align: center;
            background-color: #b655c2;
            color: #ffffff;
            font-size: 12px;
            border-radius: 2px;
            margin-right: 10px;
          }
          .course_name {
            color: #333333;
            font-size: 24px;
          }
        }
        .course_infos_header_r {
          color: #666;
          font-size: 14px;
          span {
            cursor: pointer;
            transition: all cubic-bezier(0, 1.07, 1, 1.24) 0.3s;
            &:hover {
              color: #f27200;
            }
          }
          span:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
      .course_infos_content {
        width: calc(100% - 40px);
        height: 65px;
        padding: 20px;
        background-color: #f6f6f6;
        margin-bottom: 20px;
        color: #666666;
        font-size: 14px;
        .teacher {
          margin-bottom: 10px;
        }
        .favorite {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          div:nth-child(1) {
            color: #f27200;
          }
        }
      }
      .course_infos_footer {
        .tips {
          margin-left: 10px;
          color: #333;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.mooc_tabs {
  .el-tabs__header {
    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__active-bar {
            background-color: #f27200;
          }
          .el-tabs__item {
            color: #666;
            font-size: 14px;
          }
          .el-tabs__item:hover {
            color: #f27200;
          }
          .is-active {
            color: #f27200;
          }
        }
      }
    }
  }
}
.chooseClsDialog {
  height: 620px;
  .el-dialog__header {
    border-bottom: 1px solid #ddd;
  }
  .el-dialog__body {
    height: calc(100% - 56px - 40px);
    padding: 20px;
    .el-table {
      height: 100%;
      overflow-y: auto;
      color: #999999;
      .el-table__header-wrapper {
        .el-table__header {
          .has-gutter {
            tr {
              th {
                background-color: #f2f2f2;
                color: #333333;
              }
            }
          }
        }
      }
      .orange {
        color: #f27200;
      }
      .operate_btn {
        color: #f27200;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}
.invitationCodeDialog,
.applyJoinDialog,
.wantStudyDialog,
.applyDialog {
  height: 240px;
  .el-dialog__header {
    border-bottom: 1px solid #ddd;
  }
  .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 56px - 40px - 60px);
    padding: 20px;
    .el-input {
      width: 100%;
      .el-input__inner {
        border: 0px;
        border-bottom: 1px solid #dddddd;
        color: #999999;
      }
    }
  }
  .el-dialog__footer {
    text-align: center;
    .dialog-footer {
      .el-button:nth-child(2) {
        margin-left: 70px;
      }
    }
  }
}
.applyDialog {
  height: auto !important;
}
.el-form {
  width: 100%;
}
.share {
  margin-right: 5px;
}
.el-button--text {
  color: #666666 !important;
}
.like {
  color: #f27200 !important;
}
</style>